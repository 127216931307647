import React from "react";
import Layout from "../components/layout/index";
import Seo from "../components/seo";
import Certifications from "../components/certifications/Certifications";

const Index = () => {
  return (
    <Layout>
      <Seo slug="/certifications" />
      <Certifications />
    </Layout>
  );
};

export default Index;

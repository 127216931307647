import React from "react";
import iso9001 from "../../images/iso-9001.svg";
import iso27001 from "../../images/iso-27001.svg";
import iso27001certificate from "../../images/iso-27001-2013.svg";
import iso9001certificate from "../../images/iso-9001-2005.svg";

function Certifications() {
  return (
    <div className="pb-12">
      <div className="max-w-6xl mx-auto">
        <div className="bg-[#EAFAFA] py-28 px-5 sm:px-12 flex flex-col gap-y-14 lg:grid lg:grid-cols-2 sm:items-center lg:mt-9 lg:w-11/12 xl:w-full mx-auto lg:rounded-lg">
          <h1 className="text-5xl text-darkGray text-left sm:text-center lg:text-left font-roboto">
            ISO 9001 & ISO 27001 Certified Company
          </h1>
          <div className="flex gap-12 sm:gap-8">
            <a
              href="https://teenyfy.gumlet.io/certs/bytequests-iso-9001-2015.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={iso9001}
                alt="ISO 9001"
                className="w-36 sm:w-full max-w-full"
              />
            </a>
            <a
              href="https://teenyfy.gumlet.io/certs/bytequests-iso-27001-2013.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={iso27001}
                alt="ISO 27001"
                className="w-36 sm:w-full max-w-full"
              />
            </a>
          </div>
        </div>
        <div className="lg:w-11/12 xl:w-full mx-auto pt-12 pb-16 px-5 lg:px-0">
          <h2 className="text-[40px] text-darkGray font-roboto pb-8">
            Why are ISO important? 6 key benefits of having ISO
          </h2>
          <p className="text-base text-gray font-roboto pb-5">
            It is very momentous for a company to be creditable according to
            customers. There are several ways of getting a certificate to be
            creditable within a country. But best of all is ISO certification,
            which provides you with an international certificate of credibility.
            It increases the efficiency and productivity of your business by
            increasing its credibility in order to provide a competitive edge.
            Before moving to the topic let’s know what ISO certification
            actually is and what their purpose is.
          </p>
          <h3 className="text-2xl text-darkGray font-roboto pb-6">
            What is ISO and what are their purposes?
          </h3>
          <p className="text-base text-gray font-roboto pb-7">
            The ISO(The International Organisation of Standardisation) is a
            global organisation headquartered in Geneva, Switzerland working to
            provide standardisation across an array of products and companies
            all over the world. Its main goal is to facilitate trade, but its
            focus is on the improvement of process, safety, and quality in
            several trade areas.
          </p>
          <h4 className="text-xl text-darkGray font-roboto pb-6">
            6 Benefits of ISO Certification
          </h4>
          <h4 className="text-xl text-dark-green font-roboto pb-4">
            1&#x00029; Enhancement in Customer Satisfaction
          </h4>
          <p className="text-base text-gray font-roboto pb-5">
            When your business gets successfully certified with ISO standards,
            your business process gets more defined and your quality gets
            augmented. Now you will focus more on meeting customer needs from
            your services and products. this helps in augmenting customer
            satisfaction and brings to the arena of competition.
          </p>
          <h4 className="text-xl text-dark-green font-roboto pb-4">
            2&#x00029; International Recognition and Acquittance.
          </h4>
          <p className="text-base text-gray font-roboto pb-5">
            Since we know ISO is an international organisation best known for
            maintaining quality standards, when you get certified with ISO,
            companies across the world will acknowledge it and appreciate it.
          </p>
          <h4 className="text-xl text-dark-green font-roboto pb-4">
            3&#x00029; More Customers
          </h4>
          <p className="text-base text-gray font-roboto pb-5">
            What customers always want is quality and satisfaction. Having ISO
            certification means you don't compromise on quality and you always
            maintain your standard. This helps you gain more and more customers
            as customers can now trust you for quality deliverance and
            satisfaction. This makes you stand out from the crowd in the
            competitive market.
          </p>
          <h4 className="text-xl text-dark-green font-roboto pb-4">
            4&#x00029; Consistent Quality and Efficiency
          </h4>
          <p className="text-base text-gray font-roboto pb-5">
            ISO certification comes with a lot of responsibility, you always
            know what standards you need to follow in order to complete business
            processes. Consistency in processes helps you in avoiding
            duplication,identifying the problems that occur during the work
            process, and efficient and effective ways to solve them immediately.
            companies across the world will acknowledge it and appreciate it.
          </p>
          <h4 className="text-xl text-dark-green font-roboto pb-4">
            5&#x00029; Third Party Audit Benefits
          </h4>
          <p className="text-base text-gray font-roboto pb-5">
            We always want a third party to analyse the business processes as
            they bring experience with them of the same problems and deal with
            them efficiently. A third-party experience will bring you a good
            option to observe what is going on inside your organisation's
            processes. They can provide you with efficient results and insights
            into issues that can improve product or service quality.
          </p>
          <h4 className="text-xl text-dark-green font-roboto pb-4">
            6&#x00029; More Business Opportunities
          </h4>
          <p className="text-base text-gray font-roboto pb-5">
            After getting ISO certification, you will improve your quality, and
            enhance efficiency,customer satisfaction will be high and product
            costs will decrease. All these components will help you to grab the
            potential and help you in increasing business in both current and
            new markets across the country.
          </p>
        </div>
        <div className="lg:w-11/12 xl:w-full mx-auto grid grid-cols-1 gap-y-14 sm:grid-cols-2 gap-x-28 px-5 lg:px-0">
          <div>
            <h4 className="text-xl text-dark-green font-roboto pb-4">
              ISO/IEC 27001:2013
            </h4>
            {/* <p className="text-base text-gray font-roboto pb-5">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              augue justo, porta a elit id, rhoncus placerat lorem. Aenean vel
              tellus nec metus feugiat laoreet eu id elit.
            </p> */}
            <a
              href="https://teenyfy.gumlet.io/certs/bytequests-iso-27001-2013.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <img src={iso27001certificate} alt="" />
            </a>
          </div>
          <div>
            <h4 className="text-xl text-dark-green font-roboto pb-4">
              ISO 9001:2015
            </h4>
            {/* <p className="text-base text-gray font-roboto pb-5">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              augue justo, porta a elit id, rhoncus placerat lorem. Aenean vel
              tellus nec metus feugiat laoreet eu id elit.
            </p> */}
            <a
              href="https://teenyfy.gumlet.io/certs/bytequests-iso-9001-2015.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <img src={iso9001certificate} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Certifications;
